import * as React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

interface IFields {
  heading: string,
  headingdescription: string,
  caption: string,
}

interface Props { 
  fields: IFields
 };

interface State {

};

class MyFirstComponent extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);
  }
  render() {
    const { fields } = this.props
    return (
      <React.Fragment>
        <Text field={fields.heading as any} />
        <RichText field={fields.headingdescription as any} />
        <h1>Heading 1: Mashallah </h1><RichText field={fields.caption as any} />
      </React.Fragment>
    );
  }
}

export default MyFirstComponent;

